import { emptySplitApi } from "./index";

export const adminApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsersPagedList: builder.mutation({
      query: (model) => ({
        url:
          `api/v1/auth-service/admin/all-users?` +
          `searchTerm=${model?.searchTerm || ""}` +
          `&status=${model?.status}` +
          `&pageNumber=${model?.pageNumber || 0}` +
          `&pageSize=${model?.pageSize || ""}` +
          `&orderField=${model?.orderField || "userName"}` +
          `&orderDirection=${model?.orderDirection || "asc"}`,
        method: "GET",
      }),
    }),
    createUser: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/admin/create-user`,
        method: "POST",
        body: model,
      }),
    }),
    getUserAccount: builder.query({
      query: (userId) => ({
        url: `api/v1/auth-service/admin/user-account/${userId}`,
        method: "GET",
      }),
    }),
    updateUser: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/admin/edit-user/`,
        method: "PATCH",
        body: model,
      }),
    }),
    getExpirationDateDetails: builder.mutation({
      query: (userId) => ({
        url: `api/v1/auth-service/admin/expiration-date-details/${userId}`,
        method: "GET",
      }),
    }),
    updateExpirationDate: builder.mutation({
      query: ({ planId, userId }) => ({
        url: `api/v1/auth-service/admin/update-expiration-date/${planId}/${userId}`,
        method: "PATCH",
      }),
    }),
    enableDisableAccount: builder.mutation({
      query: (userId) => ({
        url: `api/v1/auth-service/admin/enable-disable-user/${userId}`,
        method: "PATCH",
      }),
    }),
    sendResetPasswordLink: builder.mutation({
      query: (userId) => ({
        url: `api/v1/auth-service/admin/reset-password-link/${userId}`,
        method: "PATCH",
      }),
    }),
    sendSetupPasswordLink: builder.mutation({
      query: (userId) => ({
        url: `api/v1/auth-service/admin/setup-password-link/${userId}`,
        method: "PATCH",
      }),
    }),
    sendActivateAccountLink: builder.mutation({
      query: (userId) => ({
        url: `api/v1/auth-service/admin/activate-account-link/${userId}`,
        method: "PATCH",
      }),
    }),
    importPipedriveOrganization: builder.mutation({
      query: (pipedriveOrganizationCode) => ({
        url: `api/v1/auth-service/admin/import-pipedrive-organization/${pipedriveOrganizationCode}`,
        method: "GET",
      }),
    }),
    validateUserAccountCreate: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/admin/validate-account-create`,
        method: "POST",
        body: model,
      }),
    }),
    addLicensesToUser: builder.mutation({
      query: (model) => ({
        url: `api/v1/auth-service/admin/add-licenses-user`,
        method: "POST",
        body: model,
      }),
    }),
    updateSeAccess: builder.mutation({
      query: () => ({
        url: `api/v1/auth-service/admin/update-se-access`,
        method: "POST",
      }),
    }),
    addRemoveProAccess: builder.mutation({
      query: (userId) => ({
        url: `api/v1/auth-service/admin/add-remove-pro-access/${userId}`,
        method: "POST",
      }),
    }),
    restoreAdditionalUser: builder.mutation({
      query: ({ userId, ...model }) => ({
        url: `api/v1/auth-service/admin/restore-additional-user/${userId}`,
        method: "POST",
        body: model,
      }),
    }),
    getOrganizationLicenses: builder.query({
      query: (organizationId) => ({
        url: `api/v1/auth-service/admin/organization-licenses/${organizationId}`,
        method: "GET",
      }),
    }),
    deleteOrganizationLicense: builder.mutation({
      query: (licenseId) => ({
        url: `api/v1/auth-service/admin/organization-licenses/${licenseId}`,
        method: "DELETE",
      }),
    }),
    createAccessToken: builder.mutation({
      query: (userId) => ({
        url: `api/v1/auth-service/admin/access-token/create/${userId}`,
        method: "POST",
      }),
    }),
    getAccessTokenDetails: builder.mutation({
        query: (userId) => ({
          url: `api/v1/auth-service/admin/access-token/details/${userId}`,
          method: "GET",
        }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllUsersPagedListMutation,
  useCreateUserMutation,
  useGetUserAccountQuery,
  useUpdateUserMutation,
  useGetExpirationDateDetailsMutation,
  useUpdateExpirationDateMutation,
  useEnableDisableAccountMutation,
  useSendResetPasswordLinkMutation,
  useSendSetupPasswordLinkMutation,
  useSendActivateAccountLinkMutation,
  useImportPipedriveOrganizationMutation,
  useValidateUserAccountCreateMutation,
  useAddLicensesToUserMutation,
  useUpdateSeAccessMutation,
  useAddRemoveProAccessMutation,
  useRestoreAdditionalUserMutation,
  useGetOrganizationLicensesQuery,
  useDeleteOrganizationLicenseMutation,
  useCreateAccessTokenMutation,
  useGetAccessTokenDetailsMutation
} = adminApi;
