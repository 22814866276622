import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showSuccessToaster, showErrorToaster } from 'store/Shared.Slice';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Typography,
    Grid,
    FormControlLabel,
    Switch,
    Checkbox,
    Tooltip,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import FormControlWrapper from "components/FormControlWrapper";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadView from './FileUploadView';
import AutocompleteWrapper from "components/AutocompleteWrapper";
import { useSubmitFeedbackMutation } from 'Services/FeedbackService';

class FeedbackFormCustomError extends Error {
    constructor(message) {
        super();
        this.data = {
            message: message
        };
    }
}

const FeedbackForm = ({
    question,
    feedbackType,
    showUploadFiles = true,
    showUploadScreenshots,
    showGivePermission,
    showCategory = false,
    showGrantSize= false,
    onSubmit,
    onCancel,
}) => {

    const theme = useTheme();
    const dispatch = useDispatch();
   
    const defaultFormValue = {
        description: { value: "", error: "" },
        uploadFiles: false,
        uploadScreenshots: false,
        givePermission: false,
        files: [],
        categoryDescription:{ value: "", error: "" },
        grantSize:{ value: 0, error: "" },
        screenshots: [], 
        grantType:{ value: "", error: "" }
    }
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [documents, setDocuments] = useState([]); //[ { previewUrl: string, blob: file } ]
    const [screenshots, setScreenshots] = useState([]); //[ { previewUrl: string, blob: file } ]

    const [submitFeedback, { isLoading: isFeedbackSubmitting }] = useSubmitFeedbackMutation();

    const handleFormChange = (field, value) => {
        setFormValue({...formValue, [field]: value});
    };

    const [grantType, setGrantType] = useState({
        id: '',
        value: '',
        label: ''
    });
    
    const grantTypes = [
        { key: 0, value: 0, text: 'Disability' },
        { key: 1, value: 1, text: 'Community/Needs' },
        { key: 2, value: 2, text: 'Psychology' },
        { key: 3, value: 3, text: 'Aging' },
        { key: 4, value: 4, text: 'Agriculture/Food' },
        { key: 5, value: 5, text: 'Animal/Welfare' },
        { key: 6, value: 6, text: 'Children/Youth' }
        
    ];

    const handleSubmitFeedback = async () => {
        
        try {
            validate(documents, 1, 50);

            const model = new FormData();
            model.append("description", formValue.description.value);
            model.append("type", feedbackType);
            model.append("allowShare", formValue.givePermission);
            model.append("grantSize", formValue.grantSize.value);
            model.append("grantType", grantType.value)
            documents.forEach(file => {
                model.append("files", file.blob);
            });
            
            screenshots.forEach(screenshot => {
                model.append("screenshots", screenshot.blob);
            });

            const response = await submitFeedback(model).unwrap();

            dispatch(showSuccessToaster(response.message));
            onSubmit?.();
            setFormValue(defaultFormValue);
        }
        catch(error) {
            // Model Errors
            if (error.data?.errors != null) {
               const copyFormValue = { ...formValue };
               Object.keys(error.data?.errors).forEach(key => {
                   if (copyFormValue[key]) {
                       copyFormValue[key].error = error.data?.errors[key][0]
                   };
               });

               setFormValue(copyFormValue);
               dispatch(showErrorToaster('Check for errors and try again'));
           }
           // Bad Request
           else if (error.data?.message != null) {
               dispatch(showErrorToaster(error.data?.message));
           }
       }
    }

    const validate = (files, maximumUploadCount, maxFileSizeInMB) => {
        const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024;

        if(files.length > maximumUploadCount) {
            throw new FeedbackFormCustomError(`Limit exceeded: Upload up to ${maximumUploadCount} document${files > 1 ? 's' : ''}`);
        }

        var fileExceed = files.map(o => o.blob).find(document => document.size >= maxFileSizeInBytes);

        if(fileExceed) {
            throw new FeedbackFormCustomError(`${fileExceed.name} exceeds the maximum allowed size of ${maxFileSizeInMB}MB`);
        }
    }

    useEffect(() => {
        if(!formValue.uploadFiles) {
            setDocuments([]);
        }

        if(!formValue.uploadScreenshots) {
            setScreenshots([]);
        }

    }, [formValue]);

    useEffect(() => {
        return () => setFormValue(defaultFormValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ padding: 2, backgroundColor: theme.palette.background.paper }}>
            
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>{question}</Typography>

            <FormControlWrapper style={{ whiteSpace: 'pre-line' }}
                name="feedBackDescription"
                value={formValue.description.value}
                error={formValue.description.error}
                placeholder="Give as much detail as you can, but do not include any private or sensitive information."
                type="text"
                maxLength="4000"
                theme={theme}
                onChange={(element) => handleFormChange("description", { value: element.target.value, error: "" } )}
                multiline
                rows={5}
                sx={{
                        height: '140px',
                        '#feedBackDescription': { height: '80px !important' }
                }}
            />
            { showCategory && 
                <Grid item xs={12}>
                    <AutocompleteWrapper 
                        id="grantType" 
                        label="Grant Type"
                        value={grantType.label} 
                        onChange={(event, newValue) => setGrantType(newValue)}
                        theme={theme}
                        error="" 
                        options={grantTypes.map(state => ({
                            id: state.key,
                            value: state.value,
                            label: state.text 
                        }))}
                    /> 
                </Grid>
            }    
            { showGrantSize &&         
                <Grid item xs={12} md={6}>
                    <FormControlWrapper 
                        value={formValue.grantSize.value}
                        label="Grant Size"
                        maskNumber
                        theme={theme}
                        prefix={'$'}
                        shrinkLabel
                        onChange={(element) => handleFormChange("grantSize", { value: element.target.value, error: "" } )}
                    />
                </Grid>
            }
            {
                showUploadFiles &&
                <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={formValue.uploadFiles}
                            onChange={(event) => handleFormChange("uploadFiles", event.target.checked)}
                            />
                        }
                        label="Upload Files"
                    />
                </Grid>
            }          
            
            {
                formValue.uploadFiles &&
                <Grid item xs={12}>
                    <FileUploadView 
                        documents={documents} 
                        onDocumentChange={(documents) => setDocuments(documents)} 
                        tips={
                            <Typography variant="caption" color="grey.500">
                                Upload only 1 document of any type no larger than 50 MB
                                <Tooltip 
                                    title="Upload 1 file at a time, with a maximum size of 50 MB. Supported file types: All files" 
                                    sx={{verticalAlign: "middle", ml: 0.5, mb: 0.5}} 
                                >
                                    <HelpOutline fontSize="small" color="secondary" />
                                </Tooltip>
                            </Typography>
                        }
                    >
                        Attach Document
                    </FileUploadView>
                </Grid>
            }

            {
                showUploadScreenshots &&
                <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={formValue.uploadScreenshots}
                            onChange={(event) => handleFormChange("uploadScreenshots", event.target.checked)}
                            />
                        }
                        label="Include a screenshot"
                    />
                </Grid>
            }
            {
                formValue.uploadScreenshots &&
                <Grid item xs={12}>
                    <FileUploadView 
                        documents={screenshots} 
                        onDocumentChange={(screenshots) => setScreenshots(screenshots)}
                        accept="image/*"
                        multiple
                        incremental
                    >
                        Add Screenshots
                    </FileUploadView>
                </Grid>
            }
            {
                showGivePermission &&
                <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel
                        control={
                            <Switch checked= {formValue.givePermission}
                            onChange={(event) => handleFormChange("givePermission", event.target.checked)}  />
                        }
                        label="Do you give us permission to use your answer?"
                    />
                </Grid>
            }

            <Grid container mt={2} justifyContent="flex-end">
                <Grid item>
                    <LoadingButton
                        sx={{ mr: 2 }}
                        disableElevation
                        size="large"
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={onCancel}
                    >
                        Cancel
                    </LoadingButton>
                </Grid>

                <Grid item>
                    <LoadingButton
                        onClick={handleSubmitFeedback}
                        disableElevation
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        loading={isFeedbackSubmitting}
                    >
                        Submit
                    </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    )
}

export default FeedbackForm;