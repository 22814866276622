import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";

import { emptySplitApi, previewSplitApi } from "../Services";

// reducer import
import customizationReducer from "./customizationReducer";
import snackbarReducer from "./snackbarReducer";

import AuthSlice from "views/pages/authentication/Auth.Slice";
import SharedSlice from "./Shared.Slice";
import UserProfileSlice from "views/account/user-profile/ProfileInfo.Slice";
import AlertNotificationSlice from "views/account/user-profile/AlertNotification.Slice";
import AdditionalUsersSlice from "views/account/additional-users/AdditionalUsers.Slice";
import SignatureSlice from "views/account/user-profile/Signature.Slice";
import OrganizationProfileInfoSlice from "views/account/organization-profile/OrganizationProfileInfo.Slice";
import OrganizationAdditionalEmailSlice from "views/account/organization-profile/OrganizationAdditionalEmail.Slice";
import ManageAccountSlice from "views/admin/manage-accounts/ManageAccount.Slice";
import CreateAccountSlice from "views/admin/create-account/CreateAccount.Slice";
import CreateSubscriptionPlanSlice from "views/admin/create-subscription-plan/CreateSubscriptionPlan.Slice"
import UpdateAccountSlice from "views/admin/update-account/UpdateAccount.Slice";
import UpdateFoundationCaSlice from "views/admin/update-foundation-ca/UpdateFoundationCa.Slice";
import UpdateFoundationUsSlice from "views/admin/update-foundation-us/UpdateFoundationUs.Slice";
import UserProjectSlice from "views/user-project/UserProject.Slice";
import SearchEngineSlice from "views/search-engine/common/SearchEngine.Slice";
import IncomeSlice from "views/project-budget/Income.Slice";
import ExpenseSlice from "views/project-budget/Expense.Slice";
import FoundationProfileSlice from "views/foundation-profile/FoundationProfile.Slice";
import FoundationProfileSliceV2 from "views/foundation-profile-v2/FoundationProfile.Slice";
import InquirySlice from "views/document-generator/inquiry/Inquiry.Slice";
import ProposalSlice from "views/document-generator/proposal/Proposal.Slice";
import CustomSlice from "views/document-generator/custom/Custom.Slice";
import GrantSecretarySlice from "views/grant-secretary/GrantSecretary.Slice";
import GrantSecretarySliceV2 from "views/grant-secretary-v2/GrantSecretary.Slice";
import FavoritesSlice from "views/manage-funders/favorites/Favorites.Slice";
import BlockedFundersSlice from "views/manage-funders/blocked-funders/BlockedFunders.Slice";
import CustomDatabaseSlice from "views/manage-funders/custom-databases/CustomDatabase.Slice";
import FoundationProfileCASlice from "views/foundation-profile-ca/FoundationProfile.CA.Slice";
import FoundationProfileCASliceV2 from "views/foundation-profile-ca-v2/FoundationProfile.CA.Slice";
import ManageSubscriptionPlanSlice from "views/admin/manage-subscription-plans/ManageSubscriptionPlan.Slice";
import UpdateSubscriptionPlanSlice from "views/admin/update-subscription-plan/UpdateSubscriptionPlan.Slice";
import ManageCouponSlice from "views/admin/manage-coupons/ManageCoupon.Slice";
import ManageDeadlineSlice from "views/admin/manage-deadlines/ManageDeadline.Slice";
import ManageFeedbackSlice from "views/admin/manage-feedbacks/ManageFeedback.Slice";
import ThankYouLetterSlice from "views/document-generator/thank-you/ThankYou.Slice";
import ImpactReportLetterSlice from "views/document-generator/impact-report/ImpactReport.Slice";

const rtkQueryErrorLogger = (api) => (next) => (action) => {

    if (isRejectedWithValue(action)) {
        console.warn("Api Error", action);

        let doRedirect = false;

        const errorMessage = action.payload?.data?.message ?? 'Unauthorized action';
        const generalError = action.payload?.error;
        const mediaTypeError = action.payload?.data?.title ?? 'Media type error';
        
        // 401 - Unauthorized
        if (action.payload.status === 401) {
            console.error('401 - Unauthorized', action);
            doRedirect = true;
        }

        // 403 - Forbidden
        if (action.payload.status === 403) {
            console.error('403 - Forbidden', action);
            doRedirect = true;
        }

        // 415 - Unsupported Media Type
        if (action.payload.status === 415) {
            console.error('415 - Unsupported Media Type', action);
        }

        // General Errors
        if (generalError != null) {
            console.error('General Errors', action);
        }

        if (doRedirect) {
            window.setTimeout(() => {
                localStorage.clear();
                window.location.href = '/';
            }, 3000);
        }
    }

    return next(action);
};

export default configureStore({
    reducer: {
        customization: customizationReducer,
        snackbar: snackbarReducer,
        authState: AuthSlice,
        sharedState: SharedSlice,
        userProfileState: UserProfileSlice,
        alertNotificationState: AlertNotificationSlice,
        additionalUsersState: AdditionalUsersSlice,
        signatureState: SignatureSlice,
        organizationProfileState: OrganizationProfileInfoSlice,
        organizationAdditionalEmailState: OrganizationAdditionalEmailSlice,
        manageAccountState: ManageAccountSlice,
        manageSubscriptionPlanState: ManageSubscriptionPlanSlice,
        manageCouponState: ManageCouponSlice,
        manageDeadlineState: ManageDeadlineSlice,
        manageFeedbackState: ManageFeedbackSlice,
        createAccountState: CreateAccountSlice,
        createSubscriptionPlanState: CreateSubscriptionPlanSlice,
        updateSubscriptionPlanState: UpdateSubscriptionPlanSlice,
        updateAccountState: UpdateAccountSlice,
        updateFoundationCaState: UpdateFoundationCaSlice,
        updateFoundationUsState: UpdateFoundationUsSlice,
        userProjectState: UserProjectSlice,
        searchEngineState: SearchEngineSlice,
        incomeState: IncomeSlice,
        expenseState: ExpenseSlice,
        foundationProfileState: FoundationProfileSlice,
        foundationProfileStateV2: FoundationProfileSliceV2,
        inquiryState: InquirySlice,
        proposalState: ProposalSlice,
        customState: CustomSlice,
        grantSecretaryState: GrantSecretarySlice,
        grantSecretaryStateV2: GrantSecretarySliceV2,
        favoritesState: FavoritesSlice,
        blockedFundersState: BlockedFundersSlice,
        customDatabaseState: CustomDatabaseSlice,
        foundationProfileCAState: FoundationProfileCASlice,
        foundationProfileCAStateV2: FoundationProfileCASliceV2,
        thankYouLetterState: ThankYouLetterSlice,
        impactReportLetterState: ImpactReportLetterSlice,
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
        [previewSplitApi.reducerPath]: previewSplitApi.reducer
    },
    middleware: (getDefaultMiddleware) => {
        const middlewareList = [rtkQueryErrorLogger, emptySplitApi.middleware, previewSplitApi.middleware, ...getDefaultMiddleware()];
        return middlewareList;
    },
});
