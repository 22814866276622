import { emptySplitApi } from './index'

export const projectFundersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
        getAllProjectFunders: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-funders?`
                    + `pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'userName'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`
                    + `&userProjectId=${model?.userProjectId}`
                    + `&letterType=${model?.letterType}`
                    + `&withCustomDocument=${false}`,
                method: "GET"
            }),
        }),
        getAllProjectFundersCustomDocument: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-funders?`
                    + `pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'userName'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`
                    + `&userProjectId=${model?.userProjectId}`
                    + `&letterType=${model?.letterType}`
                    + `&flagFilter=${model?.flagFilter}`
                    + `&withCustomDocument=${true}`,
                method: "GET"
            }),
        }),
        getAllProjectFundersDocument: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-funders/v2?`
                    + `pageNumber=${model?.pageNumber || 0}`
                    + `&pageSize=${model?.pageSize || ''}`
                    + `&orderField=${model?.orderField || 'userName'}`
                    + `&orderDirection=${model?.orderDirection || 'asc'}`
                    + `&userProjectId=${model?.userProjectId}`
                    + `&flagFilter=${model?.flagFilter}`
                    + `&documentType=${model?.documentType || 'All'}`,
                method: "GET"
            }),
        }),
        getProjectFundersList: builder.mutation({
            query: ({ userProjectId }) => ({
                url:  `api/v1/project-service/project-funders/list/${userProjectId}`,
                method: "GET"
            })
        }),
        getFunderProjects: builder.mutation({
            query: (model) => ({
                url:  `api/v1/project-service/project-funders/funder-projects?`
                    + `funderId=${model.funderId}`
                    + `&currentProjectId=${model.currentProjectId}`,
                method: "GET",
            }),
        }),
        insertProjectFunders: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-funders`,
                method: "POST",
                body: model
            }),
        }),
        getAllProjectFundersProfileId: builder.mutation({
            query: (userProjectId) => ({
                url: `api/v1/project-service/project-funders/${userProjectId}`,
                method: "GET",
            }),
        }),
        insertFunderToProjects: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-funders/projects`,
                method: "POST",
                body: model
            }),
        }),
        getProjectFundersCustomDocument: builder.mutation({
            query: ({userProjectId, letterType}) => ({
                url: `api/v1/project-service/project-funders/custom-document/${userProjectId}/${letterType}`,
                method: "POST"
            }),
        }),
        updateFlag: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-funders/flag-update`,
                method: "POST",
                body: model
            }),
        }),
        bulkUpdateFlag: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-funders/bulk-flag-update`,
                method: "POST",
                body: model
            }),
        }),
        removeManyProjectFunders: builder.mutation({
            query: ({ userProjectId, profileIdList }) => ({
                url: `api/v1/project-service/project-funders/remove-many/${userProjectId}`,
                method: "DELETE",
                body: [...profileIdList]
            }),
        }),
        removeCurrentFundersFromOtherProjects: builder.mutation({
            query: ({ userProjectId, selectedProjectIdList }) => ({
                url: `api/v1/project-service/project-funders/remove-from-projects/${userProjectId}`,
                method: "DELETE",
                body: [...selectedProjectIdList]
            }),
        }),
        removeFundersFromProjects: builder.mutation({
            query: (model) => ({
                url: `api/v1/project-service/project-funders/remove-funders-from-projects`,
                method: "DELETE",
                body: model
            }),
        }),

    }),
  overrideExisting: false,
});

export const { 
    useGetAllProjectFundersMutation,
    useGetAllProjectFundersCustomDocumentMutation,
    useGetProjectFundersListMutation,
    useGetFunderProjectsMutation,
    useInsertProjectFundersMutation,
    useGetAllProjectFundersProfileIdMutation,
    useInsertFunderToProjectsMutation,
    useGetProjectFundersCustomDocumentMutation,
    useUpdateFlagMutation,
    useBulkUpdateFlagMutation,
    useRemoveManyProjectFundersMutation,
    useRemoveCurrentFundersFromOtherProjectsMutation,
    useRemoveFundersFromProjectsMutation,
    useGetAllProjectFundersDocumentMutation,
} = projectFundersApi;
