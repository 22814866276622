import React from "react";
import {
  DialogActions,
  useTheme,
  Grid,
  Box,
  Button,
  Typography,
  Divider,
  Tooltip,
  Chip,
} from "@mui/material";
import SimpleDialogWrapper from "components/SimpleDialogWrapper";
import MaskedInputWrapper from "components/MaskedInputWapper";
import FormControlWrapper from "components/FormControlWrapper";
import { Close, ContentCopyTwoTone, Language } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { IconRocket } from "@tabler/icons";
import { TipsAndUpdates } from "@mui/icons-material";
import { useSubmitReferralMutation } from "Services/ReferralService";
import { useState, useEffect } from "react";
import { showSuccessToaster, showErrorToaster } from "store/Shared.Slice";
import { useDispatch, useSelector } from "react-redux";

const ReferralFormPopup = ({ openPopup, onClose, formValue, setFormValue }) => {
  const theme = useTheme();
  const { userToken } = useSelector((state) => state.sharedState);

  const dispatch = useDispatch();

  const [submitReferral, { isLoading: isReferralSubmitting }] =
    useSubmitReferralMutation();


  const handleFormChange = (field, value) => {
    setFormValue({ ...formValue, [field]: value });
  };

  const referralCode = [];

  referralCode.push({
    label: "Website",
    value: `${window.location.origin}/register?referral=${userToken?.UserName}`,
    icon: <Language color="secondary" fontSize="small" />,
    endIcon: <ContentCopyTwoTone />,
  });

  const handleSubmitReferral = async () => {
    try {
      const model = {
        nameCharity: formValue.nameCharity.value || null,
        nameContact: formValue.nameContact.value || null,
        email: formValue.email.value || null,
        phoneNumber: formValue.phoneNumber.value || null,
      };

      const response = await submitReferral(model).unwrap();

      dispatch(showSuccessToaster(response.message));
      
      setFormValue({
        nameCharity: { value: "", error: "" },
        nameContact: { value: "", error: "" },
        email: { value: "", error: "" },
        phoneNumber: { value: "", error: "" }
      });

    } catch (error) {
      // Model Errors
      if (error.data?.errors != null) {
        const copyFormValue = { ...formValue };
        Object.keys(error.data?.errors).forEach((key) => {
          if (copyFormValue[key]) {
            copyFormValue[key].error = error.data?.errors[key][0];
          }
        });

        setFormValue(copyFormValue);
        dispatch(showErrorToaster("Check for errors and try again"));
      }
      // Bad Request
      else if (error.data?.message != null) {
        dispatch(showErrorToaster(error.data?.message));
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(
        showSuccessToaster(`Copied to clipboard. ${referralCode[0].value}`)
      );
    });
  };



  return (
    <SimpleDialogWrapper
      title={"Refer a Friend"}
      icon={<IconRocket fontSize="small" />}
      open={openPopup}
      onClose={onClose}
      width="600px"
      dialogContentProps={{
        sx: {
          p: 2,
          height: "auto",
          minHeight: "200px",
          minWidth: "600px",
        },
      }}
      ActionContents={
        <>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={onClose}
          >
            Close
          </Button>
        </>
      }
    >
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} lg={5.8}>
          <Box
            p={1.5}
            sx={{ bgcolor: "grey.100", borderRadius: 2 }}
            mb={1}
            display="flex"
            alignItems="center"
          >
            <TipsAndUpdates color="warning" sx={{ mr: 1 }} />
            <Typography variant="caption">
              Refer a friend to win subscription time
            </Typography>
          </Box>

          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={6} sm={6} md={6}>
              <FormControlWrapper
                name="nameCharity"
                label="Name of Charity"
                type="text"
                maxLength="40"
                theme={theme}
                required
                value={formValue.nameCharity.value}
                error={formValue.nameCharity.error}
                onChange={(element) =>
                  handleFormChange("nameCharity", {
                    value: element.target.value,
                    error: "",
                  })
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <FormControlWrapper
                name="nameContact"
                label="Name of Contact"
                type="text"
                maxLength="40"
                theme={theme}
                value={formValue.nameContact.value}
                error={formValue.nameContact.error}
                required
                onChange={(element) =>
                  handleFormChange("nameContact", {
                    value: element.target.value,
                    error: "",
                  })
                }
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <MaskedInputWrapper
                name="phoneNumber"
                label="Phone number"
                maskType="phone"
                theme={theme}
                required
                value={formValue.phoneNumber.value}
                error={formValue.phoneNumber.error}
                onChange={(element) =>
                  handleFormChange("phoneNumber", {
                    value: element.target.value,
                    error: "",
                  })
                }
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <FormControlWrapper
                name="email"
                label="E-mail"
                type="text"
                maxLength="40"
                required
                theme={theme}
                value={formValue.email.value}
                error={formValue.email.error}
                onChange={(element) =>
                  handleFormChange("email", {
                    value: element.target.value,
                    error: "",
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <DialogActions>
                <LoadingButton
                  disableElevation
                  size="large"
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={onClose} // Close button that triggers the onClose
                >
                  Close
                </LoadingButton>

                <LoadingButton
                  disableElevation
                  size="large"
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmitReferral}
                  loading={isReferralSubmitting}
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg="auto">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} lg={5.8}>
          <Box
            p={1.5}
            sx={{ bgcolor: "grey.100", borderRadius: 2 }}
            mb={1}
            display="flex"
            alignItems="center"
          >
            <TipsAndUpdates color="warning" sx={{ mr: 1 }} />
            <Typography variant="caption">
                Share your referral link to win subscription time
            </Typography>
          </Box>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
                flexWrap="wrap"
                gap={1.5}
              >
                {referralCode.map((contact) => (
                  <Tooltip title="Click to copy referral code" arrow>
                    <Chip
                      icon={contact.icon}
                      deleteIcon={contact.endIcon}
                      {...(contact.endIcon
                        ? { onDelete: () => copyToClipboard(contact.value) }
                        : {})}
                      color="secondary"
                      size="small"
                      label="Referral Code"
                      sx={{
                        p: "12.8px 5px",
                        fontSize: "0.75rem",
                        letterSpacing: 0.5,
                      }}
                    />
                  </Tooltip>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SimpleDialogWrapper>
  );
};

export default ReferralFormPopup;
