import { useState } from "react";

import { useTheme } from "@mui/material/styles";
import { Link, Typography, Grid, Box } from "@mui/material";

import ReferralFormPopup from "./ReferralForm.Popup";
import { IconRocket } from "@tabler/icons";

const ReferralSection = () => {
  const defaultFormValue = {
    nameCharity: { value: "", error: "" },
    nameContact: { value: "", error: "" },
    email: { value: "", error: "" },
    phoneNumber: { value: "", error: "" },
    success: { value: "", error: "" },
  };
  const [formValue, setFormValue] = useState(defaultFormValue);

  const [openPopupReferral, setOpenPopupReferral] = useState(false);

  const handleOpenPopupReferral = () => {
    setFormValue(defaultFormValue); 
    setOpenPopupReferral(true); 
  };

  const handleClosePopupReferral = () => {
    setOpenPopupReferral(false);
  };

  return (
    <>
      <Grid container>
        <Grid item>
          <Link
            component="button"
            onClick={handleOpenPopupReferral}
            sx={{ fontSize: 20, textAlign: "center", fontWeight: "bold" }}
            color="secondary"
          >
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
             
              <Typography
                component="span"
                variant="h4"
                sx={{
                  fontWeight: 400,
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                Refer and earn free membership
              </Typography>

              <Box component="span" sx={{ display: { xs: 'none', md: 'block', mr:4 }, flexGrow: 2 }}>
                  <IconRocket />
              </Box>
              
            </Box>
            
          </Link>
          <ReferralFormPopup
            openPopup={openPopupReferral}
            onClose={handleClosePopupReferral}
            width="600px"
            height="400px"
            formValue={formValue} 
            setFormValue={setFormValue}
          ></ReferralFormPopup>
        </Grid>
      </Grid>
    </>
  );
};

export default ReferralSection;
