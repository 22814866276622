import { emptySplitApi } from './index'

export const referralApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
   
    submitReferral: builder.mutation({
        query: (model) => ({
          url: `api/v1/auth-service/referral`,
          method: "POST",
          body: model
        }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSubmitReferralMutation,
} = referralApi;
