// assets
import {
    IconFileText,
    IconReceipt,
    IconId,
    IconTrafficLights,
    IconStars,
    IconBox,
    IconKeyboard,
    IconWorld,
    IconCalendarEvent
} from '@tabler/icons';

// constant
export const icons = {
    IconFileText,
    IconReceipt,
    IconId,
    IconTrafficLights,
    IconStars,
    IconBox,
    IconKeyboard,
    IconWorld,
    IconCalendarEvent
};

const searchEngine = {
    id: 'search-engine',
    title: 'side_menu.search_engine',
    type: 'group',
    roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
    collapse: false,
    children: [
        {
            id: 'by-giving-history',
            title: 'side_menu.by_giving_history',
            type: 'item',
            url: 'main/search/giving-history-v2',
            icon: 'IconFileText', //icons.IconFileText,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        {
            id: 'by-keyword',
            title: 'side_menu.by_keyword',
            type: 'item',
            url: 'main/search/by-keyword-v2',
            icon: 'IconKeyboard', //icons.IconBox,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        {
            id: 'by-name',
            title: 'side_menu.by_name',
            type: 'item',
            url: 'main/search/by-name-v2',
            icon: 'IconReceipt', //icons.IconReceipt,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        {
            id: 'by-director',
            title: 'side_menu.by_director',
            type: 'item',
            url: 'main/search/by-director-v2',
            icon: 'IconId', //icons.IconId,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        {
            id: 'by-year',
            title: 'side_menu.by_year',
            type: 'item',
            url: 'main/search/by-year-v2',
            icon: 'IconTrafficLights', //icons.IconTrafficLights,
            breadcrumbs: false,
            roles: ['accessus'],
        },
        /* {
            id: 'by-deadline',
            title: 'side_menu.by_deadline',
            type: 'item',
            url: 'main/search/by-deadline',
            icon: 'IconCalendarEvent', //icons.IconCalendarEvent,
            breadcrumbs: false,
            roles: ['accessus'],
        }, */
        /*{
            id: 'by-rating',
            title: 'side_menu.by_rating',
            type: 'item',
            url: 'main/search/by-rating',
            icon: icons.IconStars,
            breadcrumbs: false,
            roles: ['user', 'subuser'],
        },*/
        {
            id: 'by-giving-history-ca',
            title: 'side_menu.by_giving_history',
            type: 'item',
            url: 'main/search/giving-history-ca-v2',
            icon: 'IconFileText', //icons.IconFileText,
            breadcrumbs: false,
            roles: ['accesscanada'],
        },
        {
            id: 'by-name-ca',
            title: 'side_menu.by_name',
            type: 'item',
            url: 'main/search/name-ca-v2',
            icon: 'IconReceipt', //icons.IconReceipt,
            breadcrumbs: false,
            roles: ['accesscanada'],
        },
        {
            id: 'by-director-ca',
            title: 'side_menu.by_director',
            type: 'item',
            url: 'main/search/directors-ca-v2',
            icon: 'IconId', //icons.IconId,
            breadcrumbs: false,
            roles: ['accesscanada'],
        },
        {
            id: 'by-year-ca',
            title: 'side_menu.by_year',
            type: 'item',
            url: 'main/search/year-ca-v2',
            icon: 'IconTrafficLights', //icons.IconTrafficLights,
            breadcrumbs: false,
            roles: ['accesscanada'],
        },

        /*{
            id: 'by-rating',
            title: 'side_menu.by_rating',
            type: 'item',
            url: 'main/search/by-rating',
            icon: icons.IconStars,
            breadcrumbs: false,
            roles: ['user', 'subuser'],
        },*/
        {
            id: 'by-custom',
            title: 'side_menu.by_custom',
            type: 'item',
            url: 'main/search/by-custom-v2',
            icon: 'IconBox', //icons.IconBox,
            breadcrumbs: false,
            roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
        },
        {
            id: 'by-international-funding',
            title: 'side_menu.by_international_funding',
            type: 'item',
            url: 'main/search/by-international-funding-v2',
            icon: 'IconWorld', //icons.IconTrafficLights,
            breadcrumbs: false,
            roles: ['accessus', 'pro', 'admin', 'sales', 'superadmin'],
        },
    ]
};

export default searchEngine;
