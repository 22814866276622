import { 
    Dialog, 
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    DialogActions,
} from "@mui/material";

import { Close } from "@mui/icons-material";


const SimpleDialogWrapper = ({
    title,
    icon,
    open = false,
    onClose,
    children,
    dialogContentProps={},
    dialogActionsProps={},
    maxWidth="md",
    ActionContents: actionContents,
    ...others
}) => {

    return (
        <Dialog
            open={open} 
            onClose={onClose} 
            fullWidth 
            maxWidth={maxWidth}
            {...others}
            sx={{ 
                "& .MuiDialog-paper": { 
                    p: 0,
                    borderRadius: 2,
                },
                ...others.sx
            }}
        >
            <DialogTitle sx={{ 
                fontSize: "1rem",
                bgcolor: "secondary.main",
                color: "#fff",
                p: "12px 16px",
            }}>
                <Typography display="flex" alignItems="center" gap={1}>
                    {icon} {title}
                </Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 4,
                    color: "#fff",
                }}
            >
                <Close />
            </IconButton>

            <DialogContent 
                dividers 
                {...dialogContentProps}
                sx={{ 
                    p: 0, 
                    borderTop: "none", 
                    height: "80vh",
                    ...dialogContentProps.sx,
                }} 
            >
                {children}
            </DialogContent>
            {actionContents && (
                <DialogActions 
                    {...dialogActionsProps}
                    sx={{
                        ...dialogActionsProps.sx,
                        mt: 0.5,
                        mb: 0.5,
                    }}
                >
                    {actionContents}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default SimpleDialogWrapper;