import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateSeAccessMutation } from "Services/AdminService";
import ReactCountryFlag from "react-country-flag"
import ReactQuill, { Quill } from 'react-quill';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Link,
    Stack,
    Typography,
    Backdrop,
    CircularProgress,
    Collapse,
     
} from '@mui/material';
// third-party

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import RateReviewIcon from '@mui/icons-material/RateReview';
import FeedbackForm from './FeedbackForm';
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import QuillResize from 'quill-resize-module';

// assets
import { IconLogout, IconSettings } from '@tabler/icons';

import { useLogoutMutation } from 'Services/LoginServices';
import { showSuccessToaster } from 'store/Shared.Slice';
// ==============================|| PROFILE MENU ||============================== //
Quill.register('modules/resize', QuillResize);
const SimpleProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const { userToken } = useSelector(state => state.sharedState);
    const dispatch = useDispatch();
    const [UpdateAccess, { isLoading: isUpdatingAccess }] = useUpdateSeAccessMutation();
    const [expandedIndex, setExpandedIndex] = useState(null);
    
    const validRoles = ["Admin", "Sales", "SuperAdmin"];

    let userRoles = [];

    if (Array.isArray(userToken?.role)) {
        userRoles = userToken?.role ?? ['User'];
    }
    else {
        userRoles.push(userToken?.role ?? 'User');
    }
    const substring = "AccessUs";
    
    const accessUs = userRoles.filter((string) =>
      string.toUpperCase().includes(substring.toUpperCase())
    );
    
    const accessCanada = userRoles.filter((string) =>
      string.toUpperCase().includes("AccessCanada".toUpperCase())
    );
    
    const [selectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [openFeedback, setFeedbackOpen] = useState(false);
    
    const [logout, { isLoading }] = useLogoutMutation();

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const anchorFeedbackRef = useRef(null);

    const handleLogout = async () => {
        handleToggle();
        const responseLogout = await logout().unwrap();
        localStorage.clear();
        navigate('/', { replace: true });
        dispatch(showSuccessToaster(responseLogout.message));
    };

    const handleChangeAccess = async () => {
        
        if (validRoles.some(role => userRoles.includes(role))) {
            const response = await UpdateAccess().unwrap();

            handleToggle();
            const responseLogout = await logout().unwrap();
            localStorage.clear();
            navigate('/', { replace: true });
            dispatch(showSuccessToaster(responseLogout.message));
        } 
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleFeedbackClose = (event) => {
        if (anchorFeedbackRef.current && anchorFeedbackRef.current.contains(event.target)) {
            return;
        }
        setExpandedIndex(null);
        setFeedbackOpen(false);
    };

    const handleFeedbackSubmitted = () => {
        setFeedbackOpen(false);
        setExpandedIndex(null);
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleFeedbackToggle = () => {
        setFeedbackOpen((prevFeedbackOpen) => !prevFeedbackOpen);
    };

    const handleExpandClick = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const prevOpen = useRef(open);
    const prevFeedbackOpen = useRef(openFeedback);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;

        if (prevFeedbackOpen.current === true && openFeedback === false) {
            anchorFeedbackRef.current.focus();
        }

        prevFeedbackOpen.current = openFeedback;

    }, [open, openFeedback]);

    const openInNewTab = url => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    };

    return (
        <>
            <Link component="button" onClick={() => handleChangeAccess()} sx={{ fontSize: 20, textAlign: "left", fontWeight: "bold", borderRadius: '27px', }} color="secondary">
                
                {accessUs.length > 0 && (
                    <ReactCountryFlag
                        countryCode="US"
                        style={{
                            marginRight: '5px',
                        }}
                        svg
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                        cdnSuffix="svg"
                        title="US"
                    />
                )}

                {accessCanada.length > 0 && (
                    <ReactCountryFlag
                        countryCode="CA"
                        svg
                        style={{
                            marginRight: '5px',
                        }}
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                        cdnSuffix="svg"
                        title="CA"
                    />
                )}
                
            </Link>
            <FeedbackIcon
                sx={{
                    ...theme.typography.mediumFeedback,
                    marginRight: '14px!important',
                    marginLeft: '12px!important',
                    cursor: 'pointer',
                    color: "secondary.main"
                }}
                ref={anchorFeedbackRef}
                aria-controls={openFeedback ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleFeedbackToggle}
                >
            </FeedbackIcon>
            <Popper
                placement="bottom-end"
                open={openFeedback}
                anchorEl={anchorFeedbackRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={openFeedback} {...TransitionProps} >
                        <Paper>
                            <ClickAwayListener onClickAway={handleFeedbackClose}>
                                <MainCard sx={{ 
                                        maxWidth: 417, 
                                        maxHeight: "90vh", 
                                        overflow: "auto",
                                    }} 
                                border={false} 
                                elevation={16} 
                                content={false}
                                boxShadow 
                                shadow={theme.shadows[16]}
                            >
                                    <Box sx={{ p: 3 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h5">Classify your feedback</Typography>
                                               
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    <Box sx={{ pl: 2, pr: 2, mt: -2 }}>
                                        <List
                                            component="nav"
                                            backgroundColor= {theme.palette.background.default}
                                            sx={{
                                                width: '100%',
                                                maxWidth: 900,
                                                minWidth: 380,
                                                backgroundColor: theme.palette.background.default,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                }
                                            }}
                                        >
                                            
                                            {/*-------- Compliment --------*/}
                                            <ListItemButton
                                                backgroundColor= {theme.palette.background.default}
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 0}
                                                onClick={() => handleExpandClick(0)}
                                            >
                                                <ListItemIcon>
                                                    <FavoriteIcon color='secondary' stroke={1.5} size="20" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Give a compliment</Typography>} />
                                            </ListItemButton>
                                            <Collapse in={expandedIndex === 0} timeout="auto" unmountOnExit>
                                                <FeedbackForm 
                                                    question="What did you like? *"
                                                    feedbackType="Compliment"
                                                    showGivePermission
                                                    onSubmit={handleFeedbackSubmitted}
                                                    onCancel={() => setExpandedIndex(null)}
                                                />
                                            </Collapse>

                                            {/*-------- Issue --------*/}
                                            <ListItemButton
                                                backgroundColor= {theme.palette.background.default}
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 1} 
                                                onClick={() => handleExpandClick(1)}
                                            >
                                                <ListItemIcon>
                                                    <AssignmentLateIcon color='secondary' stroke={1.5} size="20" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Report a problem</Typography>} />
                                            </ListItemButton>
                                            <Collapse in={expandedIndex === 1} timeout="auto" unmountOnExit>
                                                <FeedbackForm 
                                                    question="What went wrong? *"
                                                    feedbackType="Issue"
                                                    showUploadScreenshots
                                                    onSubmit={handleFeedbackSubmitted}
                                                    onCancel={() => setExpandedIndex(null)}
                                                />
                                            </Collapse>

                                            {/*-------- Suggeston --------*/}
                                            <ListItemButton
                                                backgroundColor= {theme.palette.background.default}
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 2} 
                                                onClick={() => handleExpandClick(2)}
                                            >
                                                <ListItemIcon>
                                                    <RateReviewIcon color='secondary' stroke={1.5} size="20" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Make a suggestion</Typography>} />
                                            </ListItemButton>
                                            <Collapse in={expandedIndex === 2} timeout="auto" unmountOnExit>
                                                <FeedbackForm 
                                                    question="How can we improve? *"
                                                    feedbackType="Suggestion"
                                                    showUploadScreenshots
                                                    onSubmit={handleFeedbackSubmitted}
                                                    onCancel={() => setExpandedIndex(null)}
                                                />
                                            </Collapse>

                                            {/*-------- Success --------*/}
                                            <ListItemButton
                                                backgroundColor= {theme.palette.background.default}
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 3} 
                                                onClick={() => handleExpandClick(3)}
                                            >
                                                <ListItemIcon>
                                                    <ThumbUpIcon  color='secondary' stroke={1.5} size="20" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Success!</Typography>} />
                                            </ListItemButton>
                                            <Collapse in={expandedIndex === 3} timeout="auto" unmountOnExit>
                                                <FeedbackForm 
                                                    question="Share the Impact of Your Grant Award *"
                                                    feedbackType="Success"
                                                    showUploadFiles ={false}
                                                    showCategory = {true}
                                                    showGrantSize = {true}
                                                    onSubmit={handleFeedbackSubmitted}
                                                    onCancel={() => setExpandedIndex(null)}
                                                />
                                            </Collapse>

                                        </List>
                                    </Box>
                                 </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>

            <Link component="button" onClick={() => openInNewTab("https://grantadvance.supportbee.io/2439-the-simple-funding-solution")} sx={{ fontSize: 20, textAlign: "left", fontWeight: "bold" }} color="secondary">
                <Typography component="span" variant="h4" sx={
                    { fontWeight: 400, alignItems: 'center',
                        marginRight: '10px', }}>
                        Help Center
                </Typography>
            </Link>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={isLoading || isUpdatingAccess}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.secondary.dark : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit">
                    </Avatar>
                }
                label={<IconSettings stroke={1.5} size="24" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">Hi,</Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {userToken.UserName}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                    <Box sx={{ pl: 2, pr: 2, mt: -2 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="20" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                            </ListItemButton>

                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default SimpleProfileSection;
