import { createSlice } from "@reduxjs/toolkit";
import { projectFundersApi } from "Services/ProjectFundersService";
import startcase from "utils/startcase";

export const initialState = {
    userProject: {
        id: null,
        value: null,
        label: null
    },
    allProjectList: [],
    letterTypeList: [
        { id: 'Inquiry', value: 'Inquiry', label: 'Letter of Inquiry' },
        { id: 'Proposal', value: 'Proposal', label: 'Proposal' },
        { id: 'ThankYou', value: 'ThankYou', label: 'Thank You Letter' },
        { id: 'ImpactReport', value: 'ImpactReport', label: 'Impact Report Letter' },
        { id: 'CustomLetter', value: 'CustomLetter', label: 'Custom Letter' },
    ],
    letterType: {
        id: '',
        value: '',
        label: ''
    },
    funders: {
        profileId: '',
        foundationSourceType: '',
        flagFilter: 'All',
        documentType: 'All',
        checkAll: false,
        itemsSelected: [],
        excludedSelected: [],
        fundersSelected: [],
        pagedList: {
            pageNumber: 0,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0,
            orderField: 'foundationName',
            orderDirection: 'asc',
            items: []
        },
        openPopupFavourite: false,
        openPopupDelete: false,
        openPopupBlock: false,
        openPopupExportExcel: false,
        openPopupExportAll: false,
        openPopupPrintLabels: false,
        openPopupLetterheadFooterSettings: false,
        openPopupProfileSummary: false,
    },
    profileSummary: {
        profileName: 'Chesed Charity Foundation',
        lastReportYear: 2021,
        registerYear: 1973,
        stateOrProvince: 'ON',
        onlineApplication: 'http://www.torontofoundation.ca',
        webSite: 'http://www.torontofoundation.ca',
        phoneNumber: '(416)783-5395',
        fullAddress: '87 SULTANA AVE TORONTO ON M6A 1T4',
        summary: {
            avgGrantSize: 1488490,
            grantsState: 608,
            totalAsserts: 245068,
            avgGrantsAnnually: 73266744
        },
        countNotes: 2,
    },
    labelList: [
        {
            id: 'Avery5161',
            value: 'Avery 5161 (2 columns, 10 rows)',
            label: 'Avery 5161 (2 columns, 10 rows)'
        },
        {
            id: 'Avery5163',
            value: 'Avery 5163 (2 columns, 5 rows)',
            label: 'Avery 5163 (2 columns, 5 rows)'
        },
        {
            id: 'Avery5167',
            value: 'Avery 5167 (4 columns, 20 rows, for return addresses)',
            label: 'Avery 5167 (4 columns, 20 rows, for return addresses)'
        },
        {
            id: 'Avery6572',
            value: 'Avery 6572 (3 columns, 5 rows)',
            label: 'Avery 6572 (3 columns, 5 rows)'
        },
        {
            id: 'Avery6871',
            value: 'Avery 6871 (3 columns, 6 rows)',
            label: 'Avery 6871 (3 columns, 6 rows)'
        },
        {
            id: 'Avery7160',
            value: 'Avery 7160 (3 columns, 7 rows)',
            label: 'Avery 7160 (3 columns, 7 rows)'
        },
        {
            id: 'Envelope',
            value: 'Envelope',
            label: 'Envelope'
        }
    ],
};

const grantSecretarySliceV2 = createSlice({
    name: "grant-secretary-v2",
    initialState,
    reducers: {
        handleChangeUserProject: (state, action) => {
            if (action.payload !== null) {
                state.userProject = action.payload;
            }
        },
        handleFillAllProject: (state, action) => {
            state.allProjectList = action.payload;
        },
        handleChangeLetterType: (state, action) => {
            if (action.payload !== null) {
                state.letterType = state.letterTypeList.find(o => o.id === action.payload.id);
            }
        },

        handleCheckItemsChange: (state, action) => {
            const {
                itemsSelected,
                excludedSelected,
                type,
                checkAll
            } = action.payload;

            let fundersSelected = [...state[type].fundersSelected];

            fundersSelected.forEach(funder => {
                const findIndex = itemsSelected.indexOf(funder.id);

                if (findIndex === -1) {
                    fundersSelected = fundersSelected.filter(o => o.id !== funder.id);
                }

            });

            itemsSelected.forEach(item => {
                const pagedListItem = state[type].pagedList.items.find(o => o.id === item);
                const existsFunder = fundersSelected.findIndex(o => o.profileId === pagedListItem?.profileId ?? '');

                if (pagedListItem && existsFunder === -1) {
                    fundersSelected.push(pagedListItem);
                }
            });

            state[type].itemsSelected = itemsSelected;
            state[type].excludedSelected = excludedSelected;
            state[type].checkAll = checkAll;
            state[type].fundersSelected = fundersSelected
        },

        handleClear: (state, action) => {
            state.funders.checkAll = false;
            state.funders.checkAll = false;
            state.funders.itemsSelected = [];
            state.funders.excludedSelected = [];
            state.funders.fundersSelected = [];
            state.funders.pagedList = { ...initialState.funders.pagedList };
            state.funders.flagFilter = 'All';
            state.funders.documentType = 'All';

            state.letterType = { ...initialState.letterType };
            state.userProject = { ...initialState.userProject };
            state.allProjectList = [];
        },

        handleClosePopup: (state, action) => {
            state[action.payload].openPopupBlock = false;
            state[action.payload].openPopupDelete = false;
            state[action.payload].openPopupFavourite = false;
            state[action.payload].openPopupExportExcel = false;
            state[action.payload].openPopupExportAll = false;
            state[action.payload].openPopupPrintLabels = false;
            state[action.payload].openPopupLetterheadFooterSettings = false;
            state[action.payload].openPopupProfileSummary = false;
        },
        handleOpenDeletePopup: (state, action) => {
            state[action.payload].openPopupDelete = true;
            state[action.payload].openPopupFavourite = false;
            state[action.payload].openPopupBlock = false;
            state[action.payload].openPopupExportExcel = false;
            state[action.payload].openPopupExportAll = false;
            state[action.payload].openPopupPrintLabels = false;
            state[action.payload].openPopupLetterheadFooterSettings = false;
            state[action.payload].openPopupProfileSummary = false;
        },
        handleOpenPopupFavourites: (state, action) => {
            state[action.payload].openPopupFavourite = true;
            state[action.payload].openPopupBlock = false;
            state[action.payload].openPopupDelete = false;
            state[action.payload].openPopupExportExcel = false;
            state[action.payload].openPopupExportAll = false;
            state[action.payload].openPopupPrintLabels = false;
            state[action.payload].openPopupLetterheadFooterSettings = false;
            state[action.payload].openPopupProfileSummary = false;
        },
        handleOpenPopupExportExcel: (state, action) => {
            state[action.payload].openPopupExportExcel = true;
            state[action.payload].openPopupFavourite = false;
            state[action.payload].openPopupBlock = false;
            state[action.payload].openPopupDelete = false;
            state[action.payload].openPopupExportAll = false;
            state[action.payload].openPopupPrintLabels = false;
            state[action.payload].openPopupLetterheadFooterSettings = false;
            state[action.payload].openPopupProfileSummary = false;
        },
        handleOpenPopupExportAll: (state, action) => {
            state[action.payload].openPopupExportAll = true;
            state[action.payload].openPopupExportExcel = false;
            state[action.payload].openPopupFavourite = false;
            state[action.payload].openPopupBlock = false;
            state[action.payload].openPopupDelete = false;
            state[action.payload].openPopupPrintLabels = false;
            state[action.payload].openPopupLetterheadFooterSettings = false;
            state[action.payload].openPopupProfileSummary = false;
        },
        handleOpenPopupPrintLabels: (state, action) => {
            state[action.payload].openPopupPrintLabels = true;
            state[action.payload].openPopupExportAll = false;
            state[action.payload].openPopupExportExcel = false;
            state[action.payload].openPopupFavourite = false;
            state[action.payload].openPopupBlock = false;
            state[action.payload].openPopupDelete = false;
            state[action.payload].openPopupLetterheadFooterSettings = false;
            state[action.payload].openPopupProfileSummary = false;
        },
        handleOpenLetterheadFooterSettings: (state, action) => {
            state[action.payload].openPopupLetterheadFooterSettings = true;
            state[action.payload].openPopupPrintLabels = false;
            state[action.payload].openPopupExportAll = false;
            state[action.payload].openPopupExportExcel = false;
            state[action.payload].openPopupFavourite = false;
            state[action.payload].openPopupBlock = false;
            state[action.payload].openPopupDelete = false;
            state[action.payload].openPopupProfileSummary = false;
        },
        handleOpenProfileSummary: (state, action) => {
            state[action.payload.type].openPopupProfileSummary = true;
            state[action.payload.type].profileId = action.payload.profileId;
            state[action.payload.type].foundationSourceType = 'IRS'; //action.payload.foundationSourceType;

            if (action.payload.profileId.length > 10) {
                state[action.payload.type].foundationSourceType = 'CRA'
            }

            state[action.payload.type].openPopupLetterheadFooterSettings = false;
            state[action.payload.type].openPopupPrintLabels = false;
            state[action.payload.type].openPopupExportAll = false;
            state[action.payload.type].openPopupExportExcel = false;
            state[action.payload.type].openPopupFavourite = false;
            state[action.payload.type].openPopupBlock = false;
            state[action.payload.type].openPopupDelete = false;
        },
        handleOpenPopupBlockFunders: (state, action) => {
            state[action.payload].openPopupBlock = true;
            state[action.payload].openPopupProfileSummary = false;
            state[action.payload].openPopupLetterheadFooterSettings = false;
            state[action.payload].openPopupPrintLabels = false;
            state[action.payload].openPopupExportAll = false;
            state[action.payload].openPopupExportExcel = false;
            state[action.payload].openPopupFavourite = false;
            state[action.payload].openPopupDelete = false;
        },
        handleChangeFlagFilter: (state, action) => {
            state.funders.flagFilter = action.payload;
        },
        handleChangeDocumentType: (state, action) => {
            state.funders.documentType = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                projectFundersApi.endpoints.getAllProjectFundersDocument.matchFulfilled, (state, action) => {
                    state.funders.pagedList.pageNumber = action.payload.pageNumber;
                    state.funders.pagedList.pageSize = action.payload.pageSize;
                    state.funders.pagedList.pageCount = action.payload.pageCount;
                    state.funders.pagedList.totalCount = action.payload.totalCount;
                    state.funders.pagedList.orderField = action.payload.orderField;
                    state.funders.pagedList.orderDirection = action.payload.orderDirection;
                    state.funders.pagedList.items = action.payload.items?.map(o => ({ ...o, foundationName: startcase(o.foundationName) }));
                }
            )
    }
});

export const {
    handleChangeUserProject,
    handleFillAllProject,
    handleChangeLetterType,
    handleCheckItemsChange,
    handleClosePopup,
    handleOpenDeletePopup,
    handleOpenPopupFavourites,
    handleOpenPopupExportExcel,
    handleOpenPopupExportAll,
    handleOpenPopupPrintLabels,
    handleOpenLetterheadFooterSettings,
    handleOpenProfileSummary,
    handleOpenPopupBlockFunders,
    handleClear,
    handleChangeFlagFilter,
    handleChangeDocumentType,
} = grantSecretarySliceV2.actions;

export default grantSecretarySliceV2.reducer;
