import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import NumberFormatWrapper from "./NumberFormatWrapper";

import { useTranslation } from "react-i18next";

const FormControlWrapper = ({
    name, 
    value,
    label, 
    type, 
    maxLength, 
    error = '', 
    theme, 
    onChange,
    required,
    disabled,
    multiline,
    rows = 4,
    sx,
    labelSx={},
    maskNumber,
    autoFocus,
    placeholder,
    endAdornment,
    prefix='$',
    shrinkLabel,
    size="medium",
}) => {

    const { t } = useTranslation();

    return(
        <FormControl
            fullWidth
            sx={{ ...theme.typography.customInput }}
            error={error !== ""}
            required={required}
            disabled={disabled}
            size={size}
        >
           <InputLabel htmlFor={name} sx={labelSx} shrink={shrinkLabel}>{label}</InputLabel>
            <OutlinedInput
                id={name}
                type={type}
                label={label}
                inputProps={{
                    maxLength
                }}
                value={value}
                onChange={onChange}
                multiline={multiline}
                {...multiline && { rows }} 
                {...sx && { sx }}
                {...maskNumber && {inputComponent: NumberFormatWrapper, inputProps: { prefix }}}
                autoFocus={autoFocus} 
                placeholder={placeholder}
                endAdornment={endAdornment}
                
            />
            {error !== "" && (
                <FormHelperText error id={`${name}Error`}>
                    {t(error)}
                </FormHelperText>
            )}
        </FormControl>
    )
}

export default FormControlWrapper;